import Auth from './auth'

import './middleware'

// Active schemes
import scheme_003d9a64 from './schemes/local.js'
import scheme_23514a38 from './schemes/oauth2.js'

export default function (ctx, inject) {
  // Options
  const options = {"resetOnError":false,"scopeKey":"scope","rewriteRedirects":true,"fullPathRedirect":false,"watchLoggedIn":true,"redirect":{"login":"/login","logout":"/","home":"/","callback":"/login"},"vuex":{"namespace":"auth"},"cookie":{"prefix":"auth.","options":{"path":"/"}},"localStorage":{"prefix":"auth."},"token":{"prefix":"_token."},"refresh_token":{"prefix":"_refresh_token."},"defaultStrategy":"local"}

  // Create a new Auth instance
  const $auth = new Auth(ctx, options)

  // Register strategies
  // local
  $auth.registerStrategy('local', new scheme_003d9a64($auth, {"endpoints":{"login":{"url":"/api/v2/login","method":"post","propertyName":"token"},"logout":{"url":"/api/v2/logout","method":"post"},"user":{"url":"/api/auth/user","method":"get","propertyName":"user"}},"token":{"property":"token","global":true},"user":{"property":"user"},"_name":"local"}))

  // google
  $auth.registerStrategy('google', new scheme_23514a38($auth, {"scheme":"openIDConnect","client_id":"","redirect_uri":"https://www-stage.vierumaki.fi","logout_redirect_uri":"/","idToken":{"property":"id_token","maxAge":2592000,"prefix":"_id_token.","expirationPrefix":"_id_token_expiration."},"response_type":"token","grant_type":"refresh_token","scope":["openid"],"code_challenge_method":"S256","_name":"google","authorization_endpoint":"https://accounts.google.com/o/oauth2/auth","userinfo_endpoint":"https://www.googleapis.com/oauth2/v3/userinfo"}))

  // Inject it to nuxt context as $auth
  inject('auth', $auth)
  ctx.$auth = $auth

  // Initialize auth
  return $auth.init().catch(error => {
    if (process.client) {
      console.error('[ERROR] [AUTH]', error)
    }
  })
}
