import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _207a238e = () => interopDefault(import('../src/pages/checkout.vue' /* webpackChunkName: "pages/checkout" */))
const _af682c8c = () => interopDefault(import('../src/pages/index.vue' /* webpackChunkName: "pages/index" */))
const _64c1c91c = () => interopDefault(import('../src/pages/homeowner.vue' /* webpackChunkName: "pages/homeowner" */))
const _6140210a = () => interopDefault(import('../src/pages/maintenance.vue' /* webpackChunkName: "pages/maintenance" */))
const _831f19de = () => interopDefault(import('../src/pages/profile.vue' /* webpackChunkName: "pages/profile" */))
const _970f4a72 = () => interopDefault(import('../src/pages/_.vue' /* webpackChunkName: "pages/_" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/checkout",
    component: _207a238e,
    name: "checkout___fi___default"
  }, {
    path: "/en",
    component: _af682c8c,
    name: "index___en"
  }, {
    path: "/fi",
    component: _af682c8c,
    name: "index___fi"
  }, {
    path: "/homeowner",
    component: _64c1c91c,
    name: "homeowner___fi___default"
  }, {
    path: "/maintenance",
    component: _6140210a,
    name: "maintenance___fi___default"
  }, {
    path: "/profile",
    component: _831f19de,
    name: "profile___fi___default"
  }, {
    path: "/sv",
    component: _af682c8c,
    name: "index___sv"
  }, {
    path: "/en/checkout",
    component: _207a238e,
    name: "checkout___en"
  }, {
    path: "/en/homeowner",
    component: _64c1c91c,
    name: "homeowner___en"
  }, {
    path: "/en/maintenance",
    component: _6140210a,
    name: "maintenance___en"
  }, {
    path: "/en/profile",
    component: _831f19de,
    name: "profile___en"
  }, {
    path: "/fi/checkout",
    component: _207a238e,
    name: "checkout___fi"
  }, {
    path: "/fi/homeowner",
    component: _64c1c91c,
    name: "homeowner___fi"
  }, {
    path: "/fi/maintenance",
    component: _6140210a,
    name: "maintenance___fi"
  }, {
    path: "/fi/profile",
    component: _831f19de,
    name: "profile___fi"
  }, {
    path: "/sv/checkout",
    component: _207a238e,
    name: "checkout___sv"
  }, {
    path: "/sv/homeowner",
    component: _64c1c91c,
    name: "homeowner___sv"
  }, {
    path: "/sv/maintenance",
    component: _6140210a,
    name: "maintenance___sv"
  }, {
    path: "/sv/profile",
    component: _831f19de,
    name: "profile___sv"
  }, {
    path: "/",
    component: _af682c8c,
    name: "index___fi___default"
  }, {
    path: "/sv/*",
    component: _970f4a72,
    name: "all___sv"
  }, {
    path: "/fi/*",
    component: _970f4a72,
    name: "all___fi"
  }, {
    path: "/en/*",
    component: _970f4a72,
    name: "all___en"
  }, {
    path: "/*",
    component: _970f4a72,
    name: "all___fi___default"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
